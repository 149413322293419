




































































import Vue from 'vue';
import validationMessages from '@/utils/validationMessages';
import { Validation } from 'vuelidate';

export default Vue.extend({
  name: 'FieldAddress',
  inheritAttrs: false,
  props: {
    includeLatLon: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({
        street: '',
        city: '',
        state: '',
        zip: '',
        notes: '',
        latitude: '',
        longitude: '',
      }),
      required: true,
    },
    v: {
      type: Object as () => { [key: string]: Validation },
      default: Object as () => { [key: string]: Validation },
    },
  },
  methods: {
    validationMessages,
  },
});
