















import Vue from 'vue';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'AppForm',
  inheritAttrs: false,
  props: {
    errors: {
      type: Array as () => GraphQLError[],
      default: () => [],
    },
  },
  computed: {
    processedErrors(): string[] {
      if (!this.errors) return [];
      return this.errors
        .filter((error) => error.message)
        .map((error) => error.message);
    },
    hasErrors(): boolean {
      return this.processedErrors.length > 0;
    },
  },
});
