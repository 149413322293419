















































































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import FieldAddress from '@/components/FieldAddress.vue';
import FieldDate from '@/components/FieldDate.vue';
import USER from '@/graphql/queries/User.graphql';
import USER_CREATE from '@/graphql/mutations/UserCreate.graphql';
import USER_UPDATE from '@/graphql/mutations/UserUpdate.graphql';
import COOPS from '@/graphql/queries/Coops.graphql';
import { required, requiredIf } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { USER_ROLES, CODES } from '@/utils/constants';
import {
  User,
  UserCreateMutationVariables,
  UserUpdateMutationVariables,
  CoopsQuery,
  UserQueryVariables,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'AdminUsersAdd',
  components: {
    AppMain,
    AppForm,
    FieldAddress,
    FieldDate,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        role: '',
        coop: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        address: {
          street: '',
          city: '',
          state: '',
          zip: '',
          notes: '',
        },
        haulerLicense: '',
        haulerExpiration: '',
        driversLicense: '',
        driversExpiration: '',
      },
      user: {} as User,
      coops: [] as CoopsQuery['coops'],
    };
  },
  computed: {
    isCoopRole(): boolean {
      return this.form.role === USER_ROLES.COOP;
    },
    userRoles() {
      return Object.values(USER_ROLES);
    },
  },
  watch: {
    isCoopRole(newValue) {
      if (!newValue) this.form.coop = '';
    },
  },
  validations() {
    return {
      form: {
        role: { required },
        coop: { required: requiredIf((form) => form.role === USER_ROLES.COOP) },
        firstName: { required },
        lastName: { required },
        email: { required },
        address: {
          street: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
      },
    };
  },
  methods: {
    validationMessages,
    fillForm(user: User) {
      if (!user) return;
      this.form = {
        role: user.role || '',
        coop: user.coop ? user.coop.id : '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || '',
        password: '',
        phone: user.phone || '',
        address: {
          street: user.address ? user.address.street : '',
          city: user.address ? user.address.city : '',
          state: user.address ? user.address.state : '',
          zip: user.address ? user.address.zip : '',
          notes: user.address ? user.address.notes || '' : '',
        },
        haulerLicense: user.haulerLicense || '',
        haulerExpiration: user.haulerExpiration || '',
        driversLicense: user.driversLicense || '',
        driversExpiration: user.driversExpiration || '',
      };
    },
    async saveUser() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        if (this.$route.params.id) await this.updateUser();
        else await this.addUser();
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    async addUser() {
      const response = await this.$apollo.mutate({
        mutation: USER_CREATE,
        variables: {
          input: {
            user: {
              role: this.form.role,
              coopId: this.form.coop,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              email: this.form.email,
              password: this.form.password,
              phone: this.form.phone,
              haulerLicense: this.form.haulerLicense,
              haulerExpiration: this.form.haulerExpiration,
              driversLicense: this.form.driversLicense,
              driversExpiration: this.form.driversExpiration,
            },
            address: {
              street: this.form.address.street,
              city: this.form.address.city,
              state: this.form.address.state,
              zip: this.form.address.zip,
              notes: this.form.address.notes,
            },
          },
        } as UserCreateMutationVariables,
      });
      const { userCreate } = response.data;
      this.$router.replace({
        name: 'admin-users-pay-rate',
        params: { id: userCreate.user.id },
      });
      this.$notify({
        text: 'User added!',
        type: 'success',
        duration: 6000,
      });
    },
    async updateUser() {
      await this.$apollo.mutate({
        mutation: USER_UPDATE,
        variables: {
          input: {
            id: this.user.id,
            user: {
              role: this.form.role,
              coopId: this.form.coop,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              email: this.form.email,
              password: this.form.password,
              phone: this.form.phone,
              haulerLicense: this.form.haulerLicense,
              haulerExpiration: this.form.haulerExpiration,
              driversLicense: this.form.driversLicense,
              driversExpiration: this.form.driversExpiration,
            },
            address: {
              street: this.form.address.street,
              city: this.form.address.city,
              state: this.form.address.state,
              zip: this.form.address.zip,
              notes: this.form.address.notes,
            },
          },
        } as UserUpdateMutationVariables,
      });
      this.$router.replace({ name: 'admin-users' });
      this.$notify({
        text: 'User updated!',
        type: 'success',
        duration: 6000,
      });
    },
  },
  apollo: {
    user: {
      query: USER,
      variables(): UserQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ user: User }>) {
        if (!data || !data.user) this.$router.replace({ name: 'admin-users' });
        this.fillForm(data.user);
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'admin-users' });
        }
      },
      skip(): boolean {
        return !this.$route.params.id;
      },
    },
    coops: {
      query: COOPS,
      skip(): boolean {
        return !this.isCoopRole;
      },
    },
  },
});
