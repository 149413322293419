import { Validation } from 'vuelidate';

interface EnhancedValidation extends Validation {
  required?: boolean;
  decimal?: boolean;
  email?: boolean;
  multipleEmailValidator?: boolean;
  afterTime?: boolean;
  minLength?: boolean;
  sameAsPassword?: boolean;
}

export default (field: EnhancedValidation): string[] => {
  const errors = [] as string[];
  if (!field || !field.$dirty) return errors;
  field.required === false && errors.push('Required');
  field.decimal === false && errors.push('Must be a valid number');
  field.email === false && errors.push('Must be a valid email address');
  field.multipleEmailValidator === false &&
    errors.push('Must be valid email addresses, separated by comma');
  field.afterTime === false && errors.push('Must be after the previous time');
  field.sameAsPassword === false && errors.push('Passwords must match');
  if (field.$params.minLength) {
    const { min } = field.$params.minLength;
    field.minLength === false &&
      errors.push(`Must be longer than ${min} characters`);
  }
  return errors;
};
