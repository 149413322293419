



















import Vue from 'vue';
import formatDate from '@/utils/formatDate';

export default Vue.extend({
  name: 'FieldDate',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    allowedDates: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    formattedValue(): string {
      return formatDate(this.value);
    },
  },
});
